<template>
  <div class="box-border p-4 bg-dark">
    <div class="pb-4 text-xl font-normal flex items-center justify-between">
      <div class="flex-1 text-center">{{ $t('NOTIFICATION_ERROR') }}</div>
      <button
        class="w-6 h-6"
        @click="$modal.hide('modal-tx-rejected')"
      >
        <CloseIcon class="text-white" />
      </button>
    </div>
    <div class="py-4 flex items-center justify-center flex-col">
      <IconError />
      <div class="text-xl mt-3.5">{{ $t('TX_MODAL_REJECT_TEXT') }}</div>
    </div>
  </div>
</template>
<script>
import IconError from '@/components/icons/error.icon';
import CloseIcon from '@/components/icons/close.icon';

export default {
  name: 'modal-tx-rejected',
  components: {
    IconError,
    CloseIcon,
  },
  props: {},
  data() {
    return {
      amount: null,
    };
  },
};
</script>
