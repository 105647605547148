import { CHAIN_INFO } from '@/constants/chains.constant';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import { SAVED_CONNECTOR_KEY } from '../constants/common.constant';
class WcConnector {
  /**
   * @type {import('@walletconnect/ethereum-provider').default}
   */
  provider = null;
  constructor() {}

  getProvider() {
    if (!this.provider) {
      return null;
    }
    return this.provider;
  }

  async setup(chain = 1) {
    const rpcMap = {};
    Object.entries(CHAIN_INFO).forEach(([key, value]) => {
      rpcMap[key] = value.rpcUrl;
    });

    this.provider = await EthereumProvider.init({
      projectId: '4c5d3d74b84690c7cb06a1177c4c5b55',
      chains: [chain],
      rpcMap: {
        [chain]: CHAIN_INFO[chain].rpcUrl,
      },
      showQrModal: true,
    });
  }

  async connect(chain = 1) {
    // eslint-disable-next-line no-undef, no-async-promise-executor
    return await new Promise(async (resolve, reject) => {
      try {
        await this.setup(chain);
        this.provider.on('connect', async () => {
          resolve(true);
        });
        await this.provider.connect();
      } catch (err) {
        reject(err);
      }
    });
  }

  async disconnect() {
    try {
      if (this.provider) {
        await this.provider.disconnect();
      }
    } catch (err) {
      console.error(err);
    } finally {
      window.localStorage.removeItem(SAVED_CONNECTOR_KEY);
    }
  }

  toHex(chainIdDec) {
    const chainInHex = `0x${Number(chainIdDec).toString(16)}`;
    return chainInHex;
  }

  async switchChain(chainId) {
    await this.disconnect();
    await this.connect(chainId);
  }

  subscribeToEvents(chainChangeCb, accountChangeCb, disconnectCb) {
    const provider = this.provider;
    if (!provider) return;

    provider.on('accountsChanged', (accounts) => {
      if (accounts.length) {
        if (accountChangeCb) {
          accountChangeCb(accounts[0]);
        }
      } else {
        if (disconnectCb) {
          console.log('no account availabe, disconnecting...');
          disconnectCb();
        }
      }
    });

    provider.on('chainChanged', (networkId) => {
      const chainId = Number(networkId);
      if (!chainId || isNaN(chainId)) {
        if (disconnectCb) {
          disconnectCb();
        }
      } else {
        if (chainChangeCb) {
          chainChangeCb(chainId);
        }
      }
    });

    provider.on('disconnect', (error) => {
      console.error(error);
      disconnectCb();
    });
  }
}
const WcPlugin = {
  install(Vue) {
    const connector = new WcConnector();
    Vue.prototype.$wcConnector = connector;
  },
};
export default WcPlugin;
