import { Buffer } from 'buffer';
window.Buffer = window.Buffer || Buffer;
import Vue from 'vue';
import App from './app.vue';
import router from './router';
import store from './store';
import VModal from 'vue-js-modal';
import VueClipboard from 'vue-clipboard2';
import Notifications from 'vue-notification';
import VueNumber from 'vue-number-animation';
import VueSkeletonLoader from 'skeleton-loader-vue';
import i18n from '@/plugins/vue-i18n.plugin';
import AntDesignVue from 'ant-design-vue';
import WcPlugin from './helper/walletconnect';
import MetaMaskPlugin from './helper/metamask';
import ConnecterPlugin from '@/helper/connector.js';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Web3 from 'web3';
import { extend, configure } from 'vee-validate';
import { compare } from '@/utils/bignumber.util';
import VueMeta from 'vue-meta';
import Vue2Editor from 'vue2-editor';
import VueSanitize from 'vue-sanitize';
import 'ant-design-vue/dist/antd.css';
import VueQRCodeComponent from 'vue-qrcode-component';
Vue.config.productionTip = false;

import '@/assets/styles/index.style.scss';

//Vue-filter START
import '@/plugins/vue-filter-currency.plugin';
import '@/plugins/vue-filter-truncate.plugin';
import '@/plugins/cleavejs.plugin';
import '@/plugins/vue-filter-jpn-time.plugin';
import '@/plugins/vee-validate.plugin';
import { SANITIZE_CONFIG } from './constants/types.constant';

Vue.use(VModal);
Vue.use(VueClipboard);
Vue.use(Notifications);
Vue.use(VueNumber);
Vue.use(AntDesignVue);
Vue.use(MetaMaskPlugin);
Vue.use(WcPlugin);
Vue.use(ConnecterPlugin);
Vue.use(Vue2Editor);
Vue.use(VueSanitize, SANITIZE_CONFIG);

Vue.use(vueAwesomeCountdown, 'vac');
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.component('skeleton-loader', VueSkeletonLoader);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('qr-code', VueQRCodeComponent);

extend('checkTokenAddress', {
  // eslint-disable-next-line no-undef
  validate: (value) => Web3.utils.isAddress(value),
});

extend('positiveNumber', {
  validate: (value, isPositive) => {
    const number = parseFloat(value);
    return { valid: isPositive && number > 0 };
  },
});

extend('lowerThan100', {
  validate: (value) => {
    return compare('lt')(value)(100);
  },
});

extend('url', {
  validate(value) {
    try {
      if (!value) {
        return true;
      }
      var res = value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
      );
      return res !== null;
    } catch (error) {
      return false;
    }
  },
});

configure({
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`fields.${field}`);
    if (values._field_.indexOf(`fields.`) !== -1) {
      values._field_ = values._field_.replace('fields.', '');
    }
    return i18n.t(`messages.${values._rule_}`, values);
  },
});
try {
  Vue.prototype.$metamaskConnector.setup();
  Vue.prototype.$wcConnector.setup().then(() => {
    Vue.prototype.$connector.setup();
    new Vue({
      store,
      i18n,
      router,
      render: (h) => h(App),
    }).$mount('#app');
  });
} catch (err) {
  console.log(err);
}
