<template>
  <span class="language-switcher">
    <button
      @click="switchLocale('ja')"
      class="locale-button"
      :class="[locale === 'ja' ? 'locale-active' : '']"
    >
      日本語
    </button>
    <button
      @click="switchLocale('en')"
      class="locale-button"
      :class="[locale === 'en' ? 'locale-active' : '']"
    >
      English
    </button>
  </span>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  computed: {
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
  },
  methods: {
    ...mapActions({
      changeLocale: 'setting/changeLocale',
    }),
    switchLocale(locale) {
      this.changeLocale(locale);
    },
  },
};
</script>


<style lang="scss" scoped>
.language-switcher {
  display: flex;
  border: 1px solid rgb(213, 213, 213);
  border-radius: 5px;
  overflow: hidden;
}

.locale-button {
  flex: 1;
  transition: background-color 0.3s, color 0.3s;
  background-color: white;
  color: black;
  border: none;
  height: 40px !important;
  width: 80px;
  padding: 0 16px;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  height: 30px;
  font-weight: bold;

  &:hover {
    background-color: lighten(rgb(214, 214, 214), 10%);
  }
}

.locale-active {
  background-color: #FF0085 !important;
  color: white;
}

</style>