import { CHAIN_ID } from './chains.constant';

export const contracts = {
  projectManagement: {
    [CHAIN_ID.GOERLI_TESTNET]: '0x3C5f421Eb0e953923A0eB5f7D1106eC2c9f96b71',
    [CHAIN_ID.BINANCE_TESTNET]: '0xe66B041c92991f9d3901c3386bc588d8dd6c51A7',
    [CHAIN_ID.ASTAR_SHIBUYA_TESTNET]: '0x85DD2EF0E7b4316219Fa0087fA92e1Ed26F8aBfe',
    [CHAIN_ID.POLYGON_TESTNET]: '0x10945Fb979d38A3eB08c9bC0eeC3C5b2AD431c43',
    [CHAIN_ID.AVAX_TESTNET]: '0x6Ce8e69CA88A5f1e1514227916b5fA125E127135',
    [CHAIN_ID.ETH_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.AVAX_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.BINANCE_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.POLYGON_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.ASTAR_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.MCHVERSE_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.TCGVERSE_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
    [CHAIN_ID.HOMEVERSE_MAINNET]: '0xB6C7E7e0Cd3BCC1feF4922E33743c7DA2bEaD3e5',
  },
};
