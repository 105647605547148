<template>
  <div class="default-footer mt-5">
    <div class="default-footer__top">
      <div>
        <ul class="text-area flex items-center justify-center text-base">
          <li>
            <a
              @click="handleNavigation('/about')">
              {{ $t('ABOUT_ZAIFINO2') }}</a>
          </li>
          <li>
            <a
              target="_blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLSfHg2W1wtK7BLULnRFZJAqTvB7FvtvUpLmGcBwAwLwduxk2CA/viewform?pli=1"
              >{{ $t('SALES_FORM') }}</a
            >
          </li>
          <li>
            <a
              v-if="locale === 'en'"
              target="_blank"
              href="https://lp.zaif-ino.com/pdf/terms_EN_20221121.pdf"
              >{{ $t('TERM_AND_SERVICE') }}</a
            >
            <a
              v-else
              href="https://lp.zaif-ino.com/pdf/terms_20221114.pdf"
              target="_blank"
              >&nbsp;{{ $t('TERM_AND_SERVICE') }}</a
            >
          </li>
          <li>
            <a
              target="_blankf"
              href="https://lp.zaif-ino.com/pdf/privacy.pdf"
              >{{ $t('POLICY') }}</a
            >
          </li>
          <li>
            <a
              target="_blank"
              href="https://lp.zaif-ino.com/pdf/doc_law.pdf"
              >{{ $t('COMERCIAL_NOTATION') }}</a
            >
          </li>
        </ul>
      </div>
      <div class="logo-social-icons">
        <div class="logo">
              <img 
                src="/images/zaif_footer_logo.svg"
                @click="goHome()">
                alt="Zaif INO"
              />
        </div>
        <div class="footer-social-icons">
          <a href="https://line.me/R/ti/p/@841xxews?oat_content=url" target="_blank" title="LINE">
            <img src="https://api.zaif-ino.com/static/line_icon.png">
          </a>
          <a href="https://discord.com/invite/QvbqhjRZVs" target="_blank" title="Discord">
            <img src="https://api.zaif-ino.com/static/discord_blue_icon.svg">
          </a>
          <a href="https://zaif-ino.com/media/mailmagazine/" target="_blank" title="メルマガ">
            <img src="https://api.zaif-ino.com/static/merumaga_icon.png" class="newsletter-icon">
          </a>
          <a href="https://x.com/zaif_ino" target="_blank" title="X">
            <img src="https://api.zaif-ino.com/static/X_black_icon.png" class="X-icon">
          </a>
        </div>
      </div>
    </div>
    <div class="caica-copyright">
      <div class="default-footer__copyright">{{ $t('COPY_RIGHT') }}</div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  components: {
  },
  name: 'DefaultFooter',
  data() {
    return {};
  },
  computed: {
    ...mapState({
      locale: (state) => state?.setting.locale,
    }),
  },
  methods:{
    goHome() {
      // 既にルートにいる場合
      if (this.$route.path === '/') {
        window.location.reload();
      // 異なるページにいる場合、ルートに遷移
      } else {
        this.$router.push('/');
      }
    },
    handleNavigation(route) {
      // 同じページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
        window.scrollTo({
            // ページの最初の位置にスクロール
            top: 0,
            // スムーズスクロール
            behavior: 'smooth'
        })
      }) 
      //異なるページにいる場合
      } else {
        this.$router.push(route);
      }
    }, 
  }
};
</script>

<style lang="scss">
.default-footer {
  &__top {
    background: #ffffff;
    margin-bottom: 25px;
  }
  .logo {
    max-width: 250px;
    display: flex;
    justify-content: center; /* 横方向の中央揃え */
    cursor: pointer;
  }
  ul {
    li {
      @apply mr-4;
      &:last-child {
        @apply mr-0;
      }
      a {
        color: #ffffff;
        text-decoration: underline;
      }
    }
  }
  &__copyright {
    color: #000000;
    font-size: 14px;
    text-align: right;
    background: #ffffff;
    margin-right: 5px;
    margin-bottom : 15px;
  }
}
.logo-social-icons{
  display: flex; /* 横並びに配置 */
  align-items: center; /* 垂直方向の中央揃え */
  justify-content: space-between; /* 左寄せと右寄せを両立 */
  margin-left: 43.5%;
  @media (min-width: 2160px) {
    margin-left: 45%;
  }
}
.text-area{
  height: 50px;
  margin-bottom: 30px;
  background-color: #1F2023;
  color: white;
}
.footer-social-icons{
  display: flex;
  align-items: center;
  justify-content: center; /* 横方向の中央揃え */
  gap: 16px;
  margin-right: 200px;
}

.footer-social-icons img {
    height: 30px;
    width: auto;
  }
  .footer-social-icons img.X-icon {
    height: 24px;
    width: auto;
  }
  .footer-social-icons img.newsletter-icon {
    height: 35px;
    width: auto;
  }
  .caica-copyright {
    display: flex;
    align-items: center;
    justify-content: center; 
    border-top: 1px solid #bfbfbf;
    padding-top: 10px;
  }
  /* スマホ表示用のメディアクエリ */
@media (max-width: 768px) {
  .text-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    height: auto;
    padding: 10px;
  }

  .text-area li {
    margin-bottom: 10px;
  }

  .logo-social-icons {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin-left: 0%;
    
  }

  .footer-social-icons {
    display: flex; /* 横並びに配置 */
    margin-left: 0;
    margin-right: 0px;
  }

  .logo {
    flex-direction: column;
    position: static !important;
    right: auto;
    max-width: 170px !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .caica-copyright {
    flex-direction: column;
    text-align: center;
    margin-top: 20px;
  }
}
</style>
