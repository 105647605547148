<template>
  <div>
    <ConnectWalletButton />
  </div>
</template>

<script>
import ConnectWalletButton from './ConnectWalletButton.vue';
export default {
  components: { ConnectWalletButton },
  props: {
    buttonOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>

<style></style>
