<template>
  <div>
    <div class="default-header cursor-pointer">
      <div class="default-header__logo" @click="goHome()">
        <img src="/images/zaif_logo.png" />
      </div>
      <div class="items-center hidden lg:flex">
        <div class="header-content flex items-center space-x-4">
          <div class="relative">
            <DropdownMenu />
          </div>
          <a href="https://zaif-ino.com/media/" target="_blank">
            <button class="notification-button">
              {{ $t('NOTICE') }}
            </button>
          </a>
          <div>
            <div class="social-icons">
              <a href="https://line.me/R/ti/p/@841xxews?oat_content=url" target="_blank" title="LINE">
                <img src="https://api.zaif-ino.com/static/line_icon.png">
              </a>
              <a href="https://discord.com/invite/QvbqhjRZVs" target="_blank" title="Discord">
                <img src="https://api.zaif-ino.com/static/discord_blue_icon.svg">
              </a>
              <a href="https://zaif-ino.com/media/mailmagazine/" target="_blank" title="メルマガ">
                <img src="https://api.zaif-ino.com/static/merumaga_icon.png" class="newsletter-icon">
              </a>
              <a href="https://x.com/zaif_ino" target="_blank" title="X">
                <img src="https://api.zaif-ino.com/static/X_black_icon.png" class="X-icon">
              </a>
            </div>
          </div>
          <div><LocaleSwitch></LocaleSwitch></div>
          <ConnectWallet/>
        </div>
      </div>
      <div class="block lg:hidden flex items-center space-x-4">
        <div></div>
        <ConnectWallet />
        <button @click="drawerVisible = true"><BurgerIcon /></button>
        <a-drawer
          width="100%"
          placement="right"
          :visible="drawerVisible"
          @close="drawerVisible = false"
          class="wallet-drawer"
        >
          <div class="img-section border-b">
            <img
              src="/images/zaif_logo.png"
              class="drawer-logo"
            />
            <button @click="drawerVisible = false" class="drawer-close-button">
              <BurgerCloseIcon />
            </button>
          </div>

          <!-- アイコンとクローズボタンの間にセクション分けとしてボーダーを追加 -->
          <div class="border-b px-4 py-2">
            <LocaleSwitch></LocaleSwitch>
          </div>
          <!-- About と FAQ のボタンを追加 -->
          <div class="mt-4">
            <button class="drawer-menu-button" @click="handleNavigation('/about')">
              {{ $t('ABOUT_ZAIFINO') }}
              <span class="drawer-menu-icon">></span>
            </button>
            <a href="https://zaif-ino.com/media/" target="_blank">
            <button class="drawer-menu-button">
              {{ $t('NOTICE') }}
              <span class="drawer-menu-icon">></span>
            </button>
            </a>
            <a href="https://zaif-ino.com/media/help/" target="_blank">
            <button class="drawer-menu-button">
              {{ $t('ABOUT_FAQ_GUIDE') }}
              <span class="drawer-menu-icon">></span>
            </button>
            </a>
          </div>
          <div class="px-4 py-2">
            <div class="social-icons">
              <a href="https://line.me/R/ti/p/@841xxews?oat_content=url" target="_blank" title="LINE">
                <img src="https://api.zaif-ino.com/static/line_icon.png">
              </a>
              <a href="https://discord.com/invite/QvbqhjRZVs" target="_blank" title="Discord">
                <img src="https://api.zaif-ino.com/static/discord_blue_icon.svg">
              </a>
              <a href="https://zaif-ino.com/media/mailmagazine/" target="_blank" title="メルマガ">
                <img src="https://api.zaif-ino.com/static/merumaga_icon.png" class="newsletter-icon">
              </a>
              <a href="https://x.com/zaif_ino" target="_blank" title="X">
                <img src="https://api.zaif-ino.com/static/X_black_icon.png" class="X-icon">
              </a>
            </div>
          </div>
        </a-drawer>
      </div>
      <ModalRequestConnect />
    </div>
    <div class="header-spacer"></div>  <!-- 透明スペーサーを追加 -->
  </div>
</template>

<script>
import ConnectWallet from './ConnectWallet.vue';
import BurgerIcon from './icons/burger.icon.vue';
import BurgerCloseIcon from './icons/burger_close.icon.vue';
import ModalRequestConnect from './ModalRequestConnect.vue';
import { CREATE_NFT_SITE, SEND_NFT_SITE } from '@/env';
import LocaleSwitch from '@/components/common/LocaleSwitch.vue';
import DropdownMenu from './DropdownMenu.vue';

export default {
  components: {
    ConnectWallet,
    BurgerIcon,
    BurgerCloseIcon,
    ModalRequestConnect,
    LocaleSwitch,
    DropdownMenu,
  },
  data() {
    return {
      drawerVisible: false,
      CREATE_NFT_SITE,
      SEND_NFT_SITE,
    };
  },
  computed: {
    isHomePage() {
      return this.$route.path === '/' || this.$route.path === '/about';
    },
  },
  methods:{
    closeDropdown() {
      this.drawerVisible = false;
    },
    goHome() {
      // 既にルートにいる場合
      if (this.$route.path === '/') {
        window.location.reload();
      // 異なるページにいる場合、ルートに遷移
      } else {
        this.$router.push('/');
      }
    },
    handleNavigation(route) {
      // 同じページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
        window.scrollTo({
            // ページの最初の位置にスクロール
            top: 0,
            // スムーズスクロール
            behavior: 'smooth'
        })
      }) 
      //異なるページにいる場合
      } else {
        this.$router.push(route);
      }
      // ドロップダウンを閉じる
      this.closeDropdown();
    },  
    FAQ(route) {
     // 既にaboutページにいる場合
      if (this.$route.path === route) {
        this.$nextTick(() => {
        window.scrollTo({
            // よくある質問の高さまでスクロールする
            top: document.body.scrollHeight* 2/5,
            // スムーズスクロール
            behavior: 'smooth'
          })
        }) 
        // ドロップダウンを閉じる
        this.closeDropdown();

      //異なるページにいる場合
      } else {
        this.$router.push('/about').then(() => {
          // ページ遷移後に、スクロールする
          this.$nextTick(() => {
            window.scrollTo({
              // よくある質問の高さまでスクロールする
              top: document.body.scrollHeight* 2/5,
              // スムーズスクロール
              behavior: 'smooth'
            });
          });
        });
      // ドロップダウンを閉じる
      this.closeDropdown();
      }
    }
  }
};
</script>

<style lang="scss">
.default-header {
  @apply px-8 py-6 max-w-full flex items-center justify-between bg-header-bg;
  border-bottom: 1px solid #7446c7;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
  background-color: white;

  &__logo {
    img {
      height: 20px;
      object-fit: contain;
    }
  }
}
.header-spacer {
  height: 50px;
  visibility: hidden;
}

.img-section {
  width: 100vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.drawer-logo {
  max-width: 50%;
  max-height: 40px;
}

.drawer-close-button {
  cursor: pointer;
  padding-bottom: 16px;
}

.border-b {
  border-bottom: 1px solid #ddd;
}

.drawer-menu-button {
  width: 100%;
  text-align: left;
  font-weight: bold;
  padding: 12px;
  color: black;
  border-bottom: 1px solid #ddd;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.drawer-menu-icon {
  width: 24px;
  height: 24px;
  background: #9B9DA1;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1000000px;
}
.notification-button {
  margin-right: 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.notification-button:hover {
  color: #0056b3;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center; /* 横方向の中央揃え */
  gap: 16px;
}

.social-icons a {
  margin: 0; /* 余計なマージンをリセット */
  padding: 0; /* 余計なパディングをリセット */
}
.social-icons img {
    height: 24px;
    width: auto;
  }
  .social-icons img.X-icon {
    height: 20px;
    width: auto;
  }
  .social-icons img.newsletter-icon {
    height: 30px;
    width: auto;
  }
@media (max-width: 768px) {
.social-icons img {
    height: 30px;
    width: auto;
    margin-top: 15px;
  }
  .social-icons img.X-icon {
    height: 25px;
    width: auto;
  }
  .social-icons img.newsletter-icon {
    height: 40px;
    width: auto;
  }
}
</style>