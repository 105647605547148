<template>
  <div class="box-border p-4 bg-dark">
    <div class="pb-4">
      <div class="text-xl text-right">
        <button
          class="w-3 h-3"
          @click="$modal.hide('modal-tx-succeeded')"
        >
          <CloseIcon class="text-white" />
        </button>
      </div>
    </div>
    <div class="pb-16 pt-12 flex items-center justify-center flex-col">
      <IconSuccess class="w-16 h-16 text-primary-3" />
      <div class="text-gray-3 text-xl mt-10">{{ $t('TX_MODAL_SUMMITED_TEXT') }}</div>
      <a
        :href="`${explorerUrl}/tx/${txHash}`"
        target="_blank"
        class="text-sm font-semibold mt-3.5 flex items-center text-purple-crocus hover:text-purple-crocus"
      >
        {{ $t('TX_MODAL_VIEW_EXPLORER') }}
      </a>
    </div>
  </div>
</template>
<script>
import IconSuccess from '@/components/icons/success.icon';
import CloseIcon from '@/components/icons/close.icon';
import { mapGetters } from 'vuex';

export default {
  name: 'modal-tx-succeeded',
  components: {
    IconSuccess,
    CloseIcon,
  },
  props: {
    txHash: {
      type: String,
    },
  },
  computed: {
    ...mapGetters({
      connectedChainInfo: 'wallet/connectedChainInfo',
    }),
    explorerUrl() {
      return this.connectedChainInfo.explorer;
    },
  },
};
</script>
<style lang="less"></style>
