<template>
  <a-modal
    :visible="visible"
    title=""
    :footer="null"
    :closable="false"
    @cancel="handleCancel"
    wrapClassName="modal-connect"
    destroyOnClose
  >
    <div>
      <div
        v-if="!isAccepted"
        class="request"
      >
        <div class="request__title">{{ $t('MODAL_CONNECT_TITLE') }}</div>
        <div
          v-if="locale === 'ja'"
          class="request__sub-title"
        >
          ウォレット接続することで、<a
            href="https://lp.zaif-ino.com/pdf/terms_20221114.pdf"
            target="_blank"
          >
            利用規約</a
          >
          <a
            href="https://lp.zaif-ino.com/pdf/terms_EN_20221121.pdf"
            target="_blank"
            >(ENGLISH)・
          </a>
          <a
            href="https://lp.zaif-ino.com/pdf/privacy.pdf"
            target="_blank"
            >プライバシーポリシー</a
          >に同意されるものとします
        </div>
        <div
          v-if="locale === 'en'"
          class="request__sub-title"
        >
          By connecting to the Wallet, you agree to the&nbsp;<a
            href="https://lp.zaif-ino.com/pdf/terms_20221114.pdf"
            target="_blank"
          >
            Terms of Service</a
          >
          <a
            href="https://lp.zaif-ino.com/pdf/terms_EN_20221121.pdf"
            target="_blank"
            >(ENGLISH)
          </a>
          &nbsp;and&nbsp;
          <a
            href="https://lp.zaif-ino.com/pdf/privacy.pdf"
            target="_blank"
            >Privacy Policy</a
          >.
        </div>
        <div class="request__btn">
          <button
            @click="isAccepted = true"
            class="request__btn--accept"
          >
            {{ $t('ACCEPT_CONNECT') }}
          </button>
          <button
            @click="setModalRequestState(false)"
            class="request__btn--cancel"
          >
            {{ $t('REJECT_CONNECT') }}
          </button>
        </div>
      </div>
      <div
        v-else
        class="connector"
      >
        <button
          v-for="connector in availableConnectors"
          :key="connector.id"
          class="connector__item"
          :disabled="loading"
          @click="handleConnect(toConnectChainId, connector.id)"
        >
          <div>
            <img
              :src="connector.image"
              alt=""
            />
          </div>
          <div class="mt-7 connector__item__name">
            {{ connector.name }}
          </div>
        </button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { CONNECTORS } from '@/constants/types.constant';
import { CHAIN_ID } from '@/env';
import shareListenerMixin from '../mixins/share-listener.mixin';
export default {
  data() {
    return {
      isAccepted: false,
      CHAIN_ID,
      loading: false,
    };
  },
  mixins: [shareListenerMixin],
  computed: {
    ...mapState({
      visible: (state) => state.wallet.shouldShowModalConnect,
      account: (state) => state.wallet?.provider?.address || '',
      toConnectChainId: (state) => state.wallet?.toConnectChainId,
      locale: (state) => state?.setting.locale,
    }),
    availableConnectors() {
      return [
        {
          name: 'Metamask',
          id: CONNECTORS.METAMASK,
          image: '/icons/metamask.svg',
        },
        {
          name: 'Wallet Connect',
          id: CONNECTORS.WALLET_CONNECT,
          image: '/icons/walletconnect.svg',
        },
      ];
    },
  },
  mounted() {
    console.debug('MOUTED');
  },
  methods: {
    ...mapMutations({
      setModalRequestState: 'wallet/UPDATE_SHOW_MODAL_CONNECT',
    }),
    handleCancel() {
      this.setModalRequestState(false);
      this.isAccepted = false;
    },
    async handleConnect(chainId, id) {
      this.isAccepted = false;
      this.setModalRequestState(false);
      await this.connectWallet(chainId, id);
    },
  },
};
</script>

<style lang="scss">
.modal-connect {
  .ant-modal {
    @screen xl {
      width: 1000px !important;
    }
  }
  .ant-modal-content {
    .ant-modal-body {
      @screen xl {
        padding: 72px;
      }
      .request {
        @apply text-center font-semibold;
        &__title {
          @apply text-black text-xl mb-4;
          @screen xl {
            font-size: 42px;
            line-height: 63px;
            margin-bottom: 52px;
          }
        }
        &__sub-title {
          @apply text-black text-base;
          @screen xl {
            @apply text-xl;
          }
        }

        &__btn {
          @apply w-full mx-auto;
          @screen md {
            @apply w-3/5;
          }
          @screen xl {
            font-size: 30px;
            line-height: 45px;
          }
          button {
            @apply block w-full mx-auto;
          }
          &--accept {
            background: #7845c5;
            @apply text-white p-3 my-4 text-center;
            border: 1px solid #7845c5;

            @screen xl {
              @apply my-7;
            }
          }
          &--cancel {
            @apply border border-solid border-black p-3;
          }
        }
      }
      .connector {
        @apply flex items-center justify-center p-2 flex-col;
        @screen md {
          @apply flex-row;
        }
        @screen xl {
          @apply p-5 justify-between;
        }
        &__item {
          @apply text-center border border-solid border-black;
          @apply px-6 py-4;
          @apply rounded-lg;
          @apply flex items-center justify-center flex-col;
          min-width: 180px;
          min-height: 180px;
          &:first-child {
            @apply mb-10;
            @screen md {
              @apply mr-10 mb-0;
            }
          }
          @screen md {
            @apply px-10 py-6;
            border-radius: 20px;
          }
          @screen xl {
            padding: 44px 80px;
          }
          &__name {
            @apply font-semibold;
            @apply text-base;
            @screen xl {
              font-size: 26px;
              line-height: 39px;
            }
          }
          img {
            @apply w-20 h-20;
            @screen md {
              width: 180px;
              height: 180px;
            }
          }
        }
      }
    }
  }
}
</style>
