import Vue from 'vue';
import { SAVED_CONNECTOR_KEY } from '../constants/common.constant';
import { CONNECTORS } from '../constants/types.constant';

class ConnectorManager {
  provider = null;
  adapter = null;
  constructor() {}

  setup() {
    console.log(Vue.prototype.$metamaskConnector, Vue.prototype.$wcConnector);
    switch (localStorage.getItem(SAVED_CONNECTOR_KEY)) {
      case CONNECTORS.METAMASK:
        this.adapter = Vue.prototype.$metamaskConnector;
        break;
      case CONNECTORS.WALLET_CONNECT:
        this.adapter = Vue.prototype.$wcConnector;
        break;
      default:
        break;
    }
    if (!this.adapter) return;
    this.provider = this.adapter.getProvider();
  }
}
const ConnecterPlugin = {
  install(Vue) {
    Vue.prototype.$connector = new ConnectorManager();
  },
};

export default ConnecterPlugin;
