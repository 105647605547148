<template>
  <notifications :width="300">
    <template slot="body" slot-scope="props">
      <div
        class="mt-32 mr-8 py-5 px-5 flex justify-between text-white border-solid border rounded-2xl shadow-lg bg-dark"
        :class="`notification--${props.item.type}`"
      >
        <div class="flex">
          <div
            class="w-7 h-7 bg-green rounded-full flex items-center justify-center"
            v-if="props.item.type == 'success'"
          >
            <AtomCheckIcon class="flex-none text-green-600" />
          </div>
          <div
            class="w-7 h-7 bg-red-500 rounded-full flex items-center justify-center"
            v-if="props.item.type == 'error'"
          >
            <AtomExclamationIcon class="flex-none" />
          </div>

          <div class="ml-4">
            <a class="text-sm"> {{ props.item.title }} </a>
            <div class="text-xs break-word" v-html="props.item.text"></div>
          </div>
        </div>
        <a @click="props.close" class="cursor-pointer text-sm"> X </a>
      </div>
    </template>
  </notifications>
</template>
<script>
import AtomCheckIcon from "@/components/icons/check.icon";
import AtomExclamationIcon from "@/components/icons/exclamation.icon";

export default {
  components: {
    AtomCheckIcon,
    AtomExclamationIcon,
  },
};
</script>
<style lang="scss">
.border-yellow {
  border-color: #eaa400;
}
</style>
